import { useState } from 'react'
import { toast } from 'react-toastify'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { navigate } from 'gatsby'
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextFieldProps,
  Typography,
} from '@mui/material'
import { DeleteOutline } from '@mui/icons-material'
import { TimePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import { useGetAssociationsQuery } from 'manage-tritag/services/api/endpoints/associations'
import TabPanel from 'manage-tritag/components/tab-panel'
import { useGetDivisionsQuery } from 'manage-tritag/services/api/endpoints/divisions'
import { useGetVenuesQuery } from 'manage-tritag/services/api/endpoints/venues'
import {
  Team,
  useGetTeamsQuery,
} from 'manage-tritag/services/api/endpoints/teams'
import {
  Competition,
  useAddCompetitionMutation,
} from 'manage-tritag/services/api/endpoints/competitions'

import { noLimitParams } from 'manage-tritag/utils/string'
import Spinner from 'manage-tritag/components/loading/spinner'
import { useMediaQuery } from 'react-responsive'
import {
  FormContent,
  SecondFormContent,
  StyledButton,
  StyledButtonDiv,
  StyledGridContainer,
  StyledTextField,
  DisabledTextField,
  StyledItem,
} from './styles'

const CompetitionCreateForm = () => {
  const isMobile = useMediaQuery({ query: `(max-width: 898px)` })
  const { data: associations, isLoading: assocLoading } =
    useGetAssociationsQuery(noLimitParams)
  const { data: divisions, isLoading: divisionLoading } =
    useGetDivisionsQuery(noLimitParams)
  const { data: venues, isLoading: venueLoading } =
    useGetVenuesQuery(noLimitParams)
  const { data: teams, isLoading: teamLoading } = useGetTeamsQuery({
    ...noLimitParams,
    currentSeason: true,
  })
  const [createCompetition, { isLoading: createLoading }] =
    useAddCompetitionMutation()
  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      name: '',
      association: '',
      division: '',
      grade: '',
      dayPlayed: '',
      venue: '',
      numberOfRounds: '',
      finalsSystem: '',
      startDate: '',
      startTime: moment(),
      endTime: moment(),
      repTournament: false,
      registerTeamPrice: 0,
      registerTeamStartDate: '',
      registerTeamEndDate: '',
      registerTeamStatus: '',
      registerPlayerPrice: 0,
      registerPlayerStartDate: '',
      registerPlayerEndDate: '',
      registerPlayerStatus: '',
      registerStaffPrice: 0,
      registerStaffStartDate: '',
      registerStaffEndDate: '',
      registerStaffStatus: '',
      displayMatches: false,
      displayResults: false,
      displayLadders: false,
    },
  })

  const [value, setValue] = useState(0)
  const [selectedTeam, setSelectedTeam] = useState<Team | undefined>(undefined)
  const [listOfTeam, setListOfTeams] = useState<Team[]>([])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const handleAddTeam = () => {
    if (selectedTeam) {
      // Find if the array contains an object by comparing the property value
      if (!listOfTeam.some(item => item._id === selectedTeam._id)) {
        setListOfTeams([...listOfTeam, selectedTeam])
      }
    }
  }

  const handleRemoveTeam = (id: string) => {
    if (id) {
      setListOfTeams(listOfTeam.filter(item => item._id !== id))
    }
  }

  const onSubmit: SubmitHandler<Competition> = async (createData: any) => {
    if (createLoading) return
    const formData = {
      name: createData.name,
      association: createData.association,
      division: createData.division,
      grade: createData.grade,
      dayPlayed: createData.dayPlayed,
      venue: createData.venue,
      numberOfRounds: createData.numberOfRounds,
      finalsSystem: createData.finalsSystem,
      startDate: createData.startDate,
      time: `${createData.startTime?.format(
        'hh:mm A',
      )} - ${createData.endTime?.format('hh:mm A')}`,
      repTournament: createData.repTournament,
      registrationSettings: {
        team: {
          price: createData.registerTeamPrice,
          startDate: createData.registerTeamStartDate,
          endDate: createData.registerTeamEndDate,
          open: createData.registerTeamStatus,
        },
        player: {
          price: createData.registerPlayerPrice,
          startDate: createData.registerPlayerStartDate,
          endDate: createData.registerPlayerEndDate,
          open: createData.registerPlayerStatus,
        },
        staff: {
          price: createData.registerStaffPrice,
          startDate: createData.registerStaffStartDate,
          endDate: createData.registerStaffEndDate,
          open: createData.registerStaffStatus,
        },
      },
      teams: listOfTeam.map(item => item._id),
      display: {
        matches: createData.displayMatches,
        results: createData.displayResults,
        ladders: createData.displayLadders,
      },
    }
    await createCompetition(formData).unwrap()
    reset()
    toast.success('Competition added succesfully.')
    navigate('/competitions')
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          marginBottom: '1rem',
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab
            label="Competition details"
            sx={{ fontWeight: 'bold', textTransform: 'none' }}
          />
          <Tab
            label="Registration settings"
            sx={{ fontWeight: 'bold', textTransform: 'none' }}
          />
          <Tab
            label="Teams"
            sx={{ fontWeight: 'bold', textTransform: 'none' }}
          />
          <Tab
            label="Display settings"
            sx={{ fontWeight: 'bold', textTransform: 'none' }}
          />
        </Tabs>
      </Box>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <TabPanel value={value} index={0}>
          <StyledGridContainer container>
            <Grid item xs={12} sm={12} md={12}>
              <FormContent>
                <FormControl
                  variant="filled"
                  size="small"
                  sx={{ width: '100%' }}
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    Association *
                  </InputLabel>
                  <Controller
                    name="association"
                    control={control}
                    render={({ field }) => (
                      <Select
                        inputProps={{ tabIndex: 1 }}
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        disableUnderline
                        required
                        {...field}
                      >
                        {!assocLoading &&
                          associations &&
                          associations.map(association => (
                            <MenuItem
                              key={association._id}
                              value={association._id}
                            >
                              {association.name}
                            </MenuItem>
                          ))}
                      </Select>
                    )}
                  />
                </FormControl>

                <FormControl
                  variant="filled"
                  size="small"
                  sx={{ width: '100%' }}
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    Division *
                  </InputLabel>
                  <Controller
                    name="division"
                    control={control}
                    render={({ field }) => (
                      <Select
                        inputProps={{ tabIndex: 2 }}
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        disableUnderline
                        {...field}
                        required
                      >
                        {!divisionLoading &&
                          divisions &&
                          divisions.map(division => (
                            <MenuItem key={division._id} value={division._id}>
                              {division.name}
                            </MenuItem>
                          ))}
                      </Select>
                    )}
                  />
                </FormControl>

                <Controller
                  name="grade"
                  control={control}
                  render={({ field }) => (
                    <StyledTextField
                      InputProps={{ disableUnderline: true }}
                      inputProps={{ tabIndex: 3 }}
                      id="filled-basic"
                      label="Grade"
                      variant="filled"
                      size="small"
                      {...field}
                    />
                  )}
                />
              </FormContent>

              <SecondFormContent>
                <FormControl
                  variant="filled"
                  size="small"
                  sx={{ width: '100%' }}
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    Day played *
                  </InputLabel>
                  <Controller
                    name="dayPlayed"
                    control={control}
                    render={({ field }) => (
                      <Select
                        inputProps={{ tabIndex: 4 }}
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        disableUnderline
                        {...field}
                        required
                      >
                        <MenuItem value="Monday">
                          <em>Monday</em>
                        </MenuItem>
                        <MenuItem value="Tuesday">
                          <em>Tuesday</em>
                        </MenuItem>
                        <MenuItem value="Wednesday">
                          <em>Wednesday</em>
                        </MenuItem>
                        <MenuItem value="Thursday">
                          <em>Thursday</em>
                        </MenuItem>
                        <MenuItem value="Friday">
                          <em>Friday</em>
                        </MenuItem>
                        <MenuItem value="Saturday">
                          <em>Saturday</em>
                        </MenuItem>
                        <MenuItem value="Sunday">
                          <em>Sunday</em>
                        </MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>

                <FormControl
                  variant="filled"
                  size="small"
                  sx={{ width: '100%' }}
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    Venue *
                  </InputLabel>
                  <Controller
                    name="venue"
                    control={control}
                    render={({ field }) => (
                      <Select
                        inputProps={{ tabIndex: 5 }}
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        disableUnderline
                        {...field}
                        required
                      >
                        {!venueLoading &&
                          venues &&
                          venues.map(venue => (
                            <MenuItem key={venue._id} value={venue._id}>
                              {venue.name}
                            </MenuItem>
                          ))}
                      </Select>
                    )}
                  />
                </FormControl>

                <Controller
                  name="numberOfRounds"
                  control={control}
                  render={({ field }) => (
                    <StyledTextField
                      InputProps={{ disableUnderline: true }}
                      inputProps={{ tabIndex: 6 }}
                      id="filled-basic"
                      label="Number of rounds"
                      variant="filled"
                      size="small"
                      {...field}
                      required
                    />
                  )}
                />
              </SecondFormContent>

              <SecondFormContent>
                <FormControl
                  variant="filled"
                  size="small"
                  sx={{ width: '100%' }}
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    Finals System *
                  </InputLabel>
                  <Controller
                    name="finalsSystem"
                    control={control}
                    render={({ field }) => (
                      <Select
                        inputProps={{ tabIndex: 7 }}
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        disableUnderline
                        required
                        {...field}
                      >
                        <MenuItem value="Top 2 (1 round)">
                          <em>Top 2 (1 round)</em>
                        </MenuItem>
                        <MenuItem value="Top 4 (3 rounds)">
                          <em>Top 4 (3 rounds)</em>
                        </MenuItem>
                        <MenuItem value="Top 5 (4 rounds)">
                          <em>Top 5 (4 rounds)</em>
                        </MenuItem>
                        <MenuItem value="Top 6 (4 rounds)">
                          <em>Top 6 (4 rounds)</em>
                        </MenuItem>
                        <MenuItem value="Top 8 (4 rounds)">
                          <em>Top 8 (4 rounds)</em>
                        </MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>

                <Controller
                  name="startTime"
                  control={control}
                  render={({ field }) => (
                    <TimePicker
                      label="Start time played"
                      renderInput={(iprops: TextFieldProps) => (
                        <StyledTextField
                          id="filled-basic"
                          type="time"
                          label="Start time played"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{ disableUnderline: true, tabIndex: 8 }}
                          variant="filled"
                          size="small"
                          {...iprops}
                          error={false}
                        />
                      )}
                      inputFormat="hh:mm A"
                      {...field}
                    />
                  )}
                />

                <Controller
                  name="endTime"
                  control={control}
                  render={({ field }) => (
                    <TimePicker
                      label="End time played"
                      renderInput={(iprops: TextFieldProps) => (
                        <StyledTextField
                          id="filled-basic"
                          type="time"
                          label="End time played"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{ disableUnderline: true, tabIndex: 9 }}
                          variant="filled"
                          size="small"
                          {...iprops}
                          error={false}
                        />
                      )}
                      inputFormat="hh:mm A"
                      {...field}
                    />
                  )}
                />
              </SecondFormContent>

              <SecondFormContent>
                <Controller
                  name="startDate"
                  control={control}
                  render={({ field }) => (
                    <StyledTextField
                      label="Competition Start Date"
                      type="date"
                      size="small"
                      variant="filled"
                      InputProps={{ disableUnderline: true }}
                      inputProps={{ tabIndex: 10 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      {...field}
                      required
                    />
                  )}
                />

                <Controller
                  name="repTournament"
                  control={control}
                  render={({ field }) => (
                    <FormGroup style={{ padding: '0.2rem', width: '100%' }}>
                      <FormControlLabel
                        control={<Checkbox inputProps={{ tabIndex: 11 }} />}
                        label="Rep tournament"
                        {...field}
                      />
                    </FormGroup>
                  )}
                />

                {!isMobile && <Box sx={{ width: '100%' }} />}
              </SecondFormContent>
            </Grid>
          </StyledGridContainer>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <StyledGridContainer container>
            <Grid item xs={12} sm={12} md={12}>
              <FormContent>
                <DisabledTextField
                  label="Team"
                  disabled
                  size="small"
                  variant="filled"
                  InputProps={{ disableUnderline: true }}
                  InputLabelProps={{
                    style: { color: '#000' },
                  }}
                  sx={{ bgcolor: 'none' }}
                />

                <Controller
                  name="registerTeamPrice"
                  control={control}
                  render={({ field }) => (
                    <StyledTextField
                      InputProps={{ disableUnderline: true }}
                      inputProps={{ tabIndex: 12 }}
                      id="filled-basic"
                      label={isMobile ? 'Team Price' : 'Price'}
                      variant="filled"
                      size="small"
                      {...field}
                    />
                  )}
                />

                <Controller
                  name="registerTeamStartDate"
                  control={control}
                  render={({ field }) => (
                    <StyledTextField
                      label="Registration Start Date"
                      type="date"
                      size="small"
                      variant="filled"
                      InputProps={{ disableUnderline: true }}
                      inputProps={{ tabIndex: 13 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      {...field}
                    />
                  )}
                />

                <Controller
                  name="registerTeamEndDate"
                  control={control}
                  render={({ field }) => (
                    <StyledTextField
                      label="Registration End Date"
                      type="date"
                      size="small"
                      variant="filled"
                      InputProps={{ disableUnderline: true }}
                      inputProps={{ tabIndex: 14 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      {...field}
                    />
                  )}
                />

                <FormControl
                  variant="filled"
                  size="small"
                  sx={{ width: '100%' }}
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    {isMobile ? 'Team Status' : 'Status'}
                  </InputLabel>
                  <Controller
                    name="registerTeamStatus"
                    control={control}
                    render={({ field }) => (
                      <Select
                        inputProps={{ tabIndex: 15 }}
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        disableUnderline
                        {...field}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={1}>
                          <em>Active</em>
                        </MenuItem>
                        <MenuItem value={0}>
                          <em>Inactive</em>
                        </MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
              </FormContent>

              <SecondFormContent>
                <DisabledTextField
                  label="Player"
                  disabled
                  size="small"
                  variant="filled"
                  InputProps={{ disableUnderline: true }}
                  InputLabelProps={{
                    style: { color: '#000' },
                  }}
                  sx={{ bgcolor: 'none' }}
                />

                <Controller
                  name="registerPlayerPrice"
                  control={control}
                  render={({ field }) => (
                    <StyledTextField
                      InputProps={{ disableUnderline: true }}
                      inputProps={{ tabIndex: 12 }}
                      id="filled-basic"
                      label={isMobile ? 'Player Price' : 'Price'}
                      variant="filled"
                      size="small"
                      {...field}
                    />
                  )}
                />

                <Controller
                  name="registerPlayerStartDate"
                  control={control}
                  render={({ field }) => (
                    <StyledTextField
                      label="Registration Start Date"
                      type="date"
                      size="small"
                      variant="filled"
                      InputProps={{ disableUnderline: true }}
                      inputProps={{ tabIndex: 13 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      {...field}
                    />
                  )}
                />

                <Controller
                  name="registerPlayerEndDate"
                  control={control}
                  render={({ field }) => (
                    <StyledTextField
                      label="Registration End Date"
                      type="date"
                      size="small"
                      variant="filled"
                      InputProps={{ disableUnderline: true }}
                      inputProps={{ tabIndex: 14 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      {...field}
                    />
                  )}
                />

                <FormControl
                  variant="filled"
                  size="small"
                  sx={{ width: '100%' }}
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    {isMobile ? 'Player Status' : 'Status'}
                  </InputLabel>
                  <Controller
                    name="registerPlayerStatus"
                    control={control}
                    render={({ field }) => (
                      <Select
                        inputProps={{ tabIndex: 15 }}
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        disableUnderline
                        {...field}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={1}>
                          <em>Active</em>
                        </MenuItem>
                        <MenuItem value={0}>
                          <em>Inactive</em>
                        </MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
              </SecondFormContent>

              <SecondFormContent>
                <DisabledTextField
                  label="Staff"
                  disabled
                  size="small"
                  variant="filled"
                  InputProps={{ disableUnderline: true }}
                  InputLabelProps={{
                    style: { color: '#000' },
                  }}
                  sx={{ bgcolor: 'none' }}
                />

                <Controller
                  name="registerStaffPrice"
                  control={control}
                  render={({ field }) => (
                    <StyledTextField
                      InputProps={{ disableUnderline: true }}
                      inputProps={{ tabIndex: 12 }}
                      id="filled-basic"
                      label={isMobile ? 'Staff Price' : 'Price'}
                      variant="filled"
                      size="small"
                      {...field}
                    />
                  )}
                />

                <Controller
                  name="registerStaffStartDate"
                  control={control}
                  render={({ field }) => (
                    <StyledTextField
                      label="Registration Start Date"
                      type="date"
                      size="small"
                      variant="filled"
                      InputProps={{ disableUnderline: true }}
                      inputProps={{ tabIndex: 13 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      {...field}
                    />
                  )}
                />

                <Controller
                  name="registerStaffEndDate"
                  control={control}
                  render={({ field }) => (
                    <StyledTextField
                      label="Registration End Date"
                      type="date"
                      size="small"
                      variant="filled"
                      InputProps={{ disableUnderline: true }}
                      inputProps={{ tabIndex: 14 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      {...field}
                    />
                  )}
                />

                <FormControl
                  variant="filled"
                  size="small"
                  sx={{ width: '100%' }}
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    {isMobile ? 'Staff Status' : 'Status'}
                  </InputLabel>
                  <Controller
                    name="registerStaffStatus"
                    control={control}
                    render={({ field }) => (
                      <Select
                        inputProps={{ tabIndex: 15 }}
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        disableUnderline
                        {...field}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={1}>
                          <em>Active</em>
                        </MenuItem>
                        <MenuItem value={0}>
                          <em>Inactive</em>
                        </MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
              </SecondFormContent>
            </Grid>
          </StyledGridContainer>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <StyledGridContainer
            container
            spacing={3}
            justifyContent="space-between"
          >
            <Grid item xs={12} sm={12} md={9}>
              <FormContent>
                <FormControl
                  variant="filled"
                  size="small"
                  sx={{ width: '100%' }}
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    Team
                  </InputLabel>

                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    onChange={(e: any) => {
                      if (teams && e.target.value !== '') {
                        setSelectedTeam(teams[e.target.value])
                      }
                    }}
                    disableUnderline
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {!teamLoading &&
                      teams &&
                      teams.map((team, i) => (
                        <MenuItem key={team._id} value={i}>
                          {team.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </FormContent>
            </Grid>
            <Grid item xs={12} sm={12} md={1} />
            <Grid item xs={12} sm={12} md={2}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={handleAddTeam}
                style={{ width: '100%', height: '90%' }}
              >
                ADD
              </StyledButton>
            </Grid>

            <Grid item xs={12} sm={12} md={5}>
              {listOfTeam &&
                listOfTeam.map(item => (
                  <StyledItem sx={{ maxWidth: '350px' }}>
                    <Grid container justifyContent="space-between">
                      <Grid item xs={10} sm={10} md={11}>
                        <Typography
                          sx={{ marginTop: '5px', fontWeight: 'bold' }}
                        >
                          {item.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={2} sm={2} md={1}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            bgcolor: '#FF3B30',
                            borderRadius: '4px',
                            padding: '0.2rem 1rem',
                          }}
                        >
                          <DeleteOutline
                            sx={{ color: 'white' }}
                            onClick={() => handleRemoveTeam(item._id)}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </StyledItem>
                ))}
            </Grid>
            <Grid item xs={12} sm={12} md={4} />
            <Grid item xs={12} sm={12} md={3} />
          </StyledGridContainer>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Grid item xs={12} sm={12} md={9}>
            <Controller
              name="displayMatches"
              control={control}
              render={({ field }) => (
                <FormGroup style={{ padding: '0.2rem' }}>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Display matches in match centre"
                    {...field}
                  />
                </FormGroup>
              )}
            />
            <Controller
              name="displayResults"
              control={control}
              render={({ field }) => (
                <FormGroup style={{ padding: '0.2rem' }}>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Display results in match centre"
                    {...field}
                  />
                </FormGroup>
              )}
            />
            <Controller
              name="displayLadders"
              control={control}
              render={({ field }) => (
                <FormGroup style={{ padding: '0.2rem' }}>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Display ladders in match centre"
                    {...field}
                  />
                </FormGroup>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md />
        </TabPanel>

        <StyledButtonDiv>
          <div />

          <div>
            <StyledButton
              variant="outlined"
              onClick={() => {
                navigate('/competitions')
              }}
              style={{
                border: '2px solid #008174',
                color: '#000',
                marginRight: '1.5rem',
              }}
            >
              CANCEL
            </StyledButton>
            <StyledButton
              variant="contained"
              color="primary"
              type="submit"
              style={{ width: '83px' }}
            >
              {createLoading ? <Spinner size={22} /> : 'SAVE'}
            </StyledButton>
          </div>
        </StyledButtonDiv>
      </Box>
    </Box>
  )
}

export default CompetitionCreateForm
