import { withAuthenticationRequired } from '@auth0/auth0-react'
import Layout from 'manage-tritag/components/layout'
import CreateForm from 'manage-tritag/components/pages/competitions/create-form'

const CreateCompetitionPage = () => {
  return (
    <Layout label1="Competitions" label2="Create" url="/competitions">
      <CreateForm />
    </Layout>
  )
}

export default withAuthenticationRequired(CreateCompetitionPage)
